<div class="main-wrapper">
  <div class="d-flex flex-row">
    <div id="sidebar" [ngClass]="{'collapsed': !sidebarExpand }">
      <div id="dismiss" (click)="sidebarExpand=false">
        <i class="pi pi-arrow-left"></i>
      </div>

      <img src="/assets/images/bpi-logo-white.svg" class="mt-5 mx-4" alt="" style="width: 40%;">

      <ul class="menus list-unstyled components mt-5">
        <li [ngClass]="{'active': mainRoute === 'dashboard'}">
          <div class="d-flex flex-row mb-2">
            <img [ngClass]="{'disabled' : !termsAndConditions}" src="/assets/images/dashboard-icon-white.svg" alt=""
                 style="width: 16px;">
            <div class="align-self-center mx-3">
              <a *ngIf="!termsAndConditions" class="disabled">Dashboard</a>
              <a *ngIf="termsAndConditions" [routerLink]="['/dashboard']">Dashboard</a>
            </div>
          </div>
        </li>
        <li [ngClass]="{'active': mainRoute === 'buyer'}">
          <div class="d-flex flex-row mb-2">
            <img [ngClass]="{'disabled' : !termsAndConditions}" src="/assets/images/buyer-icon-white.svg" alt=""
                 style="width: 16px;">
            <div class="align-self-center mx-3">
              <a *ngIf="!termsAndConditions" class="disabled">Buyer</a>
              <a *ngIf="termsAndConditions" [routerLink]="['/buyer']">Buyer</a>
            </div>
          </div>
        </li>
        <li [ngClass]="{'active': mainRoute === 'account'}">
          <div class="d-flex flex-row mb-2">
            <img src="/assets/images/pilon_profile.svg" alt="" style="width: 16px;">
            <div class="align-self-center mx-3"><a [routerLink]="['/account/edit']">Profile</a></div>
          </div>
        </li>
        <li [ngClass]="{'active': mainRoute === 'invoice'}" class="noStyle">
          <div class="d-flex flex-row">
            <img [ngClass]="{'disabled' : !termsAndConditions}" src="/assets/images/invoice-icon-white.svg" alt=""
                 style="width: 16px;">
            <div *ngIf="termsAndConditions" class="align-self-center mx-3">Invoices</div>
            <div *ngIf="!termsAndConditions" class="align-self-center mx-3 disabled">Invoices</div>
          </div>
        </li>
        <li>
          <ul [ngClass]="{'disabled': !termsAndConditions}" class="child-menu mx-2 mt-3">
            <li [ngClass]="{'active': childRoute === '/invoice/doc/list/all'}"><a
              [routerLink]="!termsAndConditions ? [] : ['/invoice/doc/list/all']">All Invoices</a></li>
            <li [ngClass]="{'active': childRoute === '/invoice/doc/list/available'}"><a
              [routerLink]="!termsAndConditions ? [] : ['/invoice/doc/list/available']">Available</a></li>
            <li [ngClass]="{'active': childRoute === '/invoice/doc/list/expired'}"><a
              [routerLink]="!termsAndConditions ? [] : ['/invoice/doc/list/expired']">Expired</a></li>
            <li [ngClass]="{'active': childRoute === '/invoice/doc/list/pending_check'}"
                *ngIf="user && user.is_matrix > 0"><a
              [routerLink]="!termsAndConditions ? [] : ['/invoice/doc/list/pending_check']">Pending Check</a></li>
            <li [ngClass]="{'active': childRoute === '/invoice/doc/list/pending_approval'}"
                *ngIf="user && user.is_matrix > 1"><a
              [routerLink]="!termsAndConditions ? [] : ['/invoice/doc/list/pending_approval']">Pending Approval</a></li>
            <li [ngClass]="{'active': childRoute === '/invoice/doc/list/allotted'}"><a
              [routerLink]="!termsAndConditions ? [] : ['/invoice/doc/list/allotted']">Scheduled</a></li>
            <li [ngClass]="{'active': childRoute === '/invoice/doc/list/funding'}"><a
              [routerLink]="!termsAndConditions ? [] : ['/invoice/doc/list/funding']">Processing</a></li>
            <li [ngClass]="{'active': childRoute === '/invoice/doc/list/failed'}"><a
              [routerLink]="!termsAndConditions ? [] : ['/invoice/doc/list/failed']">Not Successful</a></li>
            <li [ngClass]="{'active': childRoute === '/invoice/doc/list/disbursed'}"><a
              [routerLink]="!termsAndConditions ? [] : ['/invoice/doc/list/disbursed']">Funded</a></li>
          </ul>
        </li>
        <li *ngIf="user && user.is_auto_availment_allowed">
          <div class="d-flex flex-row mb-2">
            <img [ngClass]="{'disabled' : !termsAndConditions}" src="/assets/images/pilon_investment.svg" alt=""
                 style="width: 16px;">
            <div class="align-self-center mx-3"><a [routerLink]="['/preference']">Preference</a></div>
          </div>
        </li>
        <li>
          <div class="d-flex flex-row mb-2">
            <img src="/assets/images/logout.svg" style="width: 16px;">
            <a class="align-self-center mx-3" [routerLink]="['/login']">
              <div class="text-gold">Logout</div>
            </a>
          </div>
        </li>
      </ul>
      <!-- <div class="text-center poweredby-cont" style="position: relative">
        <div style="position: absolute;bottom:20px;    width: 100%;text-align: center">
            Powered by
            <a href="https://www.pilon.sg" target="_blank">
            <img src="/assets/images/pilon-logo-white.svg" alt="" style="width: 65px; vertical-align: top;"></a> {{env.version}}
        </div>
      </div> -->
    </div>
    <div id="content-side">
      <div id="topbar-wrapper">
        <div id="topbar-container" class="custom-drop-shadow"
             [ngClass]="{'sidebar-expanded': (sidebarExpand && !overlayEnabled)}">
          <div id="topbar" style="height:100%">
            <div class="row no-gutters h-100 align-items-center mx-3">
              <div class="col">
                <div class="row align-items-center" *ngIf="!sidebarExpand">
                  <div class="col-1 p-0 bigscrn">
                    <img src="/assets/images/bpi-logo.png" alt="" style="width: 100%;">
                  </div>
                  <div class="col-auto p-0 mt-1" *ngIf="!viewScreen">
                    <button type="button" id="sidebarExpandBtn" class="navbar-btn" (click)="sidebarExpand=true">
                      <i class="pi pi-bars text-red"></i>
                    </button>
                  </div>
                  <div class="col-10 bigscrn" *ngIf="user && user.buyers && user.buyers.length > 0 && !viewScreen">
                    <div class="row">
                      <div class="col-auto">
                        <select class="buyer-select" style="-webkit-appearance: checkbox !important;"
                                [(ngModel)]="default_buyer_id">
                          <option *ngFor="let buyer of user.buyers " value="{{buyer.id}}">
                            {{ buyer.company.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col p-0">
                        <button class="btn btn-primary" (click)="updateDefaultBuyer()">GO
                          <app-spinner *ngIf="update_buyer_loader"></app-spinner>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-11 row smallscrn"
                       *ngIf="user && user.buyers && user.buyers.length > 0 && !viewScreen">
                    <div class="col-auto">
                      <select class="buyer-select" style="-webkit-appearance: checkbox !important;" [(ngModel)]="default_buyer_id">
                        <option *ngFor="let buyer of user.buyers " value="{{buyer.id}}">
                          {{ buyer.company.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col p-0">
                      <button class="btn btn-primary" (click)="updateDefaultBuyer()">GO
                        <app-spinner *ngIf="update_buyer_loader"></app-spinner>
                      </button>
                    </div>
                  </div>
                </div>
                <div *ngIf="user && user.buyers && user.buyers.length > 0 && sidebarExpand && !viewScreen"
                     class="d-flex">
                  <select class="buyer-select" style="width:200px" [(ngModel)]="default_buyer_id">
                    <option *ngFor="let buyer of user.buyers " value="{{buyer.id}}">
                      {{ buyer.company.name }}
                    </option>
                  </select>
                  <button class="btn btn-primary mx-2" (click)="updateDefaultBuyer()">GO
                    <app-spinner *ngIf="update_buyer_loader"></app-spinner>
                  </button>
                </div>
                <div *ngIf="user && user.buyers && user.buyers.length > 0 && sidebarExpand && viewScreen"
                     (click)="onBack()" style="cursor: pointer;">
                  <i class="pi pi-angle-left text-red"
                     style="vertical-align:middle;margin-top:-6px;font-size:30px!important;"></i>
                  <span class="text-red f20 px-2">Back</span>
                </div>
                <div *ngIf="user && user.buyers && user.buyers.length > 0 && !sidebarExpand && viewScreen"
                     class="smallscrn" (click)="onBack()" style="cursor: pointer;">
                  <i class="pi pi-angle-left text-red"
                     style="vertical-align:middle;margin-top:-6px;font-size:30px!important;"></i>
                  <span class="text-red f20 px-2">Back</span>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex float-end">
                  <div class="text-red">
                    <b class="f18" *ngIf="mainRoute === 'dashboard'">DASHBOARD</b>
                    <b class="f18" *ngIf="mainRoute === 'buyer'">BUYER</b>
                    <b class="f18" *ngIf="mainRoute === 'invoice'">INVOICES</b>
                    <b class="f18" *ngIf="mainRoute === 'account'">PROFILE</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="page-wrapper" [ngClass]="{'sidebar-expanded': (sidebarExpand && !overlayEnabled)}">
        <div id="page-content " class="px-5 py-3">
          <div *ngIf="user && user.default_buyer && user.default_buyer.is_under_maintenance"
               class="card mb-3 bg-lightblue">
            <div class="card-header">
              <i class="fa fa-exclamation-triangle pr-2" aria-hidden="true"></i>
              <b>UNDER MAINTENANCE</b>
            </div>
            <div class="card-body">
              <p class="card-text ">

                We're currently undergoing scheduled maintenance.
                We apologize for any inconvenience this may cause.
                Please check back later.<br><br>
                Thank you for your patience.


                <br><br>
                For more information and guidance <br>
                Email: cmscare@robinsonbank.com.ph <br>
                Landline: (02) 8702-9500 locals 48642, 48648, 48650 <br>
                Mobile: (63) 929-6498443; (63) 961-7909285
              </p>
            </div>
          </div>

          <div class="card mb-3 " *ngIf="advisory">
            <div class="card-header">
              <i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
              <b>Digital Corporate Banking Advisory</b>
            </div>
            <div class="card-body">
              <p class="card-text" [innerHTML]="advisory">
              </p>
            </div>
          </div>
          <router-outlet></router-outlet>
        </div>


        <!-- Dark Overlay element -->
        <div class="overlay " [ngClass]="{'sidebar-expanded': sidebarExpand}" *ngIf="overlayEnabled"
             (click)="sidebarExpand=false"></div>

        <br><br>

      </div>
      <div class="w-100" style="background-color:#fff;position:fixed;bottom:0">
        <div class="my-3 text-muted font-weight-thin f12" style="margin-left: 310px">© Copyright 2023. PILON PTE
          LTD {{ env.version }}.{{ env.build }}
        </div>
      </div>
    </div>
  </div>
</div>


<p-toast position="top-right" key="topright"></p-toast>
