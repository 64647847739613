import * as moment from "moment";
import {BaseModel} from "../../../model/base-model";
import {Invoice} from "./invoice";
import {Loan, Project} from "./inv-document.service";
import {UserService} from "../../../model/user.service";
import {UserStatic} from "../../../model/user";

export class DiscountDetails extends BaseModel {
  early_payment_date: string;
  due_date2_days: string;
  days: number;
  interest_rate_pr: number;
  interest_rate_pa: number;
  interest_amount: number;
  platform_fee_amount: number;
  total_finance_charges: number;
  total_charges: number;
  combined_discount_rate_pa: number;
  dst: number;

  amount_receivable: number;
  range: number;

  init(value) {

    this.interest_rate_pr = 0;
    if (value && value.interest_rate_pr) {
      this.interest_rate_pr = parseFloat(value.interest_rate_pr);
    }
    super.init(value);
  }
}


export class CompanyInfo extends BaseModel {
  name: string;
}

export class Buyer extends BaseModel {
  companyInfo: CompanyInfo

  init(value) {
    if (value.companyInfo) {
      this.companyInfo = new CompanyInfo(value.companyInfo);
    }
  }
}

export const DocumentInvoiceStatic = {
  STATUS_AVAILABLE: 1,
  STATUS_PENDING_REQUEST: 2, // allotted / scheduled
  STATUS_FUNDING_PROGRESS: 3,
  STATUS_DISBURSED: 4,
  STATUS_FAILED_FUNDING: 5,
  STATUS_EXPIRED: 6,
  STATUS_COMPLETED: 7,
  STATUS_OVERDUE: 8,
  STATUS_PENDING_CHECK: 9,
  STATUS_PENDING_APPROVAL: 10,
}


class InvoiceCustomerData extends BaseModel {
  customer_id: number;
  type: string;
  name: string;
}


class InvDocumentData extends BaseModel {
  total_amount: number;
  submission_date: string;
  discountDetails: DiscountDetails;

  init(value) {
    if (value.discountDetails) {
      this.discountDetails = new DiscountDetails(value.discountDetails);
    }
    super.init(value);

  }
}

export class InvDocument extends BaseModel {

  _selected = false;
  allowed_availment:boolean;

  id: string;
  document_no: string;
  reference_no: string;
  client_code: string;
  amount: number;
  doc_date: string;
  due_date: string;
  due_date2: string;
  data: InvDocumentData;
  created_at: string;
  status: number;
  status_label: string;
  buyer: Buyer;
  disbursement_date: string;
  is_paid: boolean;
  has_dst: boolean;

  loan_id: string;
  project_id: string;

  product_type: number;
  product_code: string;

  is_available: boolean;
  is_overdue: boolean;
  can_cancel: boolean;

  early_payment_date: string;

  invoices: Invoice[];

  project: Project;
  loan: Loan;
  discountDetails: DiscountDetails;
  activities: {
    created_at: string,
    created_at_formatted: string,
    title: string,
    description: string,
    user: {
      username: string,
      full_name: string,
    },
    data: {
      action: string,
      reason: string
    }
  }[] ;

  allowed_min_date: string;
  allowed_max_date: string;
  default_selected_date: string;
  remarks: string;
  reject_reason: string;

  __hasError = false;
  __errorMsg = '';
  __min_date_orig = null;
  __min_date = null;
  __default_date = null;
  __max_date = null;

  __availability_end = 0;
  _cancelLoader = false;
  _rejectLoader = false;
  _approveLoader = false;

  init(value) {
    let _data = {};
    if (value.data) {
      _data = JSON.parse(value.data);
      this.data = new InvDocumentData(_data);
    }
    if (value.buyer) {
      this.buyer = new Buyer(value.buyer);
    }
    this.invoices = [];
    this.activities = value.activities || [];

    if (value.invoices) {
      console.log(value.invoices)
      value.invoices.forEach(inv => {
        this.invoices.push(new Invoice(inv));
      })
    }
    if (value.discountDetails) {
      this.discountDetails = new DiscountDetails(value.discountDetails);
    }
    this.updateMinMaxDate();

    super.init(value);
  }

  updateMinMaxDate() {
    this.__min_date = moment(this.allowed_min_date, 'YYYY-MM-DD').toDate();
    this.__max_date = moment(this.allowed_max_date, 'YYYY-MM-DD').toDate();
    if (this.default_selected_date) {
      this.__default_date = moment(this.default_selected_date, 'YYYY-MM-DD').toDate();
    }else if(this.allowed_min_date){
        this.__default_date = moment(this.allowed_min_date, 'YYYY-MM-DD').toDate();
    }
  }

  isAvailableForEarly() {
    // this is base on the due date, status must auto changed by cron job to expire base on this
    return this.is_available;
  }

  isStatusAvailable() {
    // this is base on current status value
    return this.status == DocumentInvoiceStatic.STATUS_AVAILABLE;

  }

  isPendingRequest() {
    return this.status == DocumentInvoiceStatic.STATUS_PENDING_REQUEST;
  }

  isAllotted() {
    return this.isPendingRequest();
  }

  isPendingCheck() {
    return this.status == DocumentInvoiceStatic.STATUS_PENDING_CHECK;
  }

  isPendingApproval() {
    return this.status == DocumentInvoiceStatic.STATUS_PENDING_APPROVAL;
  }

  isPublished() {
    return this.status == DocumentInvoiceStatic.STATUS_FUNDING_PROGRESS;
  }

  isOverdue() {
    return this.status == DocumentInvoiceStatic.STATUS_OVERDUE
  }


  isExpired() {
    return this.status == DocumentInvoiceStatic.STATUS_EXPIRED;
  }


  isDisbursed() {
    return this.status == DocumentInvoiceStatic.STATUS_DISBURSED;
  }

  isNotSuccessful() {
    return this.status == DocumentInvoiceStatic.STATUS_FAILED_FUNDING;
  }

  isPaid() {
    return this.status == DocumentInvoiceStatic.STATUS_COMPLETED;
  }

  getDataHash(web3) {

    const data = {
      dpcument_no: this.document_no,
      due_date: this.due_date,
      amount: this.amount,
    };
    return web3.utils.keccak256(JSON.stringify(data));

  }

  _statusClassName() {
    return {
      'text-success': this.isAvailableForEarly(),
      'text-warning': this.isPublished(),
      'text-info': this.isDisbursed(),
      'text-danger': this.isOverdue()
    }
  }

  canCancel() {
    if (this.can_cancel) {
      const now = moment().format('YYYY-MM-DD');
      return this.early_payment_date > now;
    }
    return this.can_cancel;
  }

  canReject() {
    return (this.status == DocumentInvoiceStatic.STATUS_PENDING_CHECK || this.status == DocumentInvoiceStatic.STATUS_PENDING_APPROVAL);
  }

  canApprove() {
    return (this.status == DocumentInvoiceStatic.STATUS_PENDING_CHECK || this.status == DocumentInvoiceStatic.STATUS_PENDING_APPROVAL);
  }

  displayDueDate() {
    if (
      (this.status == DocumentInvoiceStatic.STATUS_PENDING_REQUEST ||
      this.status == DocumentInvoiceStatic.STATUS_FUNDING_PROGRESS ||
      this.status == DocumentInvoiceStatic.STATUS_FAILED_FUNDING ||
      this.status == DocumentInvoiceStatic.STATUS_DISBURSED ||
      this.status == DocumentInvoiceStatic.STATUS_PENDING_CHECK ||
      this.status == DocumentInvoiceStatic.STATUS_PENDING_APPROVAL ||
      this.status == DocumentInvoiceStatic.STATUS_COMPLETED) &&
      this.due_date2
    ) {
      return this.due_date2;
    }
    return this.due_date;
  }

  statementLabel(){
    if (this.product_type == UserStatic.PRODUCT_TYPE_LOAN) {
      return 'Download Disclosure Agreement';
    }
    return 'Settlement Advice';
  }

}

