
export const environment = {
  production: true,
  version: 'v2.0.0',
  build: '10',
  apiHost: 'https://supply-chain-staging.pilon.sg/v1',
  apiInvoiceHost: '//ec2-18-140-56-206.ap-southeast-1.compute.amazonaws.com:7001',
  tokenName: 'user-token',
  xdebug: false,

  // bsDatePickerConfig: {containerClass: 'theme-blue', dateInputFormat: 'YYYY-MM-DD'}
  display_date_format: 'MM/DD/YYYY',
  ga_id: null,

};
